<template>
    <v-data-table :headers="headers" :items="items" class="elevation-1 mt-4" height="200px">
        <template v-slot:top>
            <v-toolbar flat class="d-flex justify-center">
                <v-file-input label="Import file" color="primary" @change="handleFileUpload" class="pt-5 mx-auto"
                    prepend-icon="" variant="outlined"></v-file-input>
            </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">

            <v-icon size="small" class="me-2" @click="selectElement(item)">
                mdi-arrow-left
            </v-icon>

        </template>
        <template v-slot:[`item.actions2`]="{ item }">
            <v-icon size="small" class="me-2" @click="syncElement(item)">
                mdi-check
            </v-icon>
        </template>
    </v-data-table>
</template>


<script>
export default {
    data() {
        return {
            headers: [
                { title: 'Get', value: 'actions', sortable: false }, // Non-sortable column
                { title: 'Sync', value: 'actions2', sortable: false }, // Non-sortable column
                { title: 'City', value: 'city' },
                { title: 'Top Left', value: 'topLeftCoordinates' },
                { title: 'Bottom Right', value: 'bottomRightCoordinates' },
            ],
            items: []
        };
    },
    methods: {
        selectElement(item) {
            this.$emit('select-element', item.city, item.topLeftCoordinates, item.bottomRightCoordinates);
        },
        handleFileUpload(event) {
            const file = event.target.files[0];
            if (file && file.type === "application/json") {
                const reader = new FileReader();
                reader.readAsText(file, "UTF-8");
                reader.onload = evt => {
                    const rawData = JSON.parse(evt.target.result);
                    this.items = rawData.map(item => ({
                        city: item.city,
                        topLeftCoordinates: `${item.top_left.latitude}, ${item.top_left.longitude}`,
                        bottomRightCoordinates: `${item.bottom_right.latitude}, ${item.bottom_right.longitude}`
                    }));
                };
                reader.onerror = () => {
                    console.error("Error reading the file");
                };
            } else {
                console.error("Please select a valid JSON file");
            }
        }

    }
};
</script>
