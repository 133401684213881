<template>
    <v-container>
        <v-form @submit.prevent="submitForm">
            <v-text-field v-model="query" label="Query" />
            <v-text-field v-model="localTopLeft" label="Top Left Coordinates (lat, lon)" />
            <v-text-field v-model="localBottomRight" label="Bottom Right Coordinates (lat, lon)" />
            <v-text-field v-model="outputFileName" label="Output File Name" />
            <v-btn type="submit" color="#ff7260">Run Script</v-btn>
        </v-form>
        <div v-if="results">
            <!-- Display results here -->
        </div>
    </v-container>
</template>

<script>
export default {
    props: {
        topLeft: String,
        bottomRight: String,
        cityName: String
    },
    data() {
        return {
            query: 'psychiatrist',
            localTopLeft: '',
            localBottomRight: '',
            outputFileName: '',
            results: null
        };
    },
    watch: {
        topLeft(newVal) {
            this.localTopLeft = newVal;
        },
        bottomRight(newVal) {
            this.localBottomRight = newVal;
        },
        cityName(newVal) {
            this.outputFileName = newVal.replace(/, /g, '_').replace(/ /g, '-');
        }
    },
    methods: {
        async submitForm() {
            const [topLeftLat, topLeftLon] = this.localTopLeft.split(',').map(Number);
            const [bottomRightLat, bottomRightLon] = this.localBottomRight.split(',').map(Number);

            const response = await fetch('http://localhost:3000/runScript', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    query: this.query,
                    topLeft: { lat: topLeftLat, lon: topLeftLon },
                    bottomRight: { lat: bottomRightLat, lon: bottomRightLon },
                    outputFileName: this.outputFileName
                })
            });
            this.results = await response.json();
        }
    }
};
</script>
