import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import '@mdi/font/css/materialdesignicons.css'
import posthogPlugin from "@/plugins/posthog"; //import the plugin. 


// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'dark' // This sets the default theme to dark
  }
})

vuetify.theme.dark = true;

createApp(App)
  .use(vuetify)
  .use(router)
  .use(posthogPlugin)
  .mount('#app')
