import { createRouter, createWebHistory } from 'vue-router'
import ScraperView from '@/views/ScraperView.vue'
import LeadsView from '@/views/LeadsView.vue'
import EmailsView from '@/views/EmailsView.vue'
import AuthView from '@/views/AuthView.vue'; // Import the new auth view
import PlaygroundView from '@/views/PlaygroundView.vue';
import ClinicsView from '@/views/ClinicsView.vue';
import { getAuth, onAuthStateChanged } from "firebase/auth";


let isAuthInitialized = false;

onAuthStateChanged(getAuth(), (user) => {
  if (!isAuthInitialized) {
    isAuthInitialized = true;
    if (user) {
      router.push('/home');
    } else {
      router.push('/auth');
    }
  }
});


const requireAuth = (to, from, next) => {
  const user = getAuth();

  if(!user.currentUser) {
    next({name: 'auth'})
  } else {
    next()
  }
}

const routes = [
  {
    path: '/auth',
    name: 'auth',
    component: AuthView, // Add the auth view route
  },
  {
    path: '/',
    redirect: '/home', // Redirect root path to /home
  },
  {
    path: '/home',
    name: 'home',
    component: ScraperView,
    beforeEnter: requireAuth    
  },
  {
    path: '/leads',
    name: 'leads',
    component: LeadsView,
    beforeEnter: requireAuth    
  },
  {
    path: '/emails',
    name: 'emails',
    component: EmailsView,
    beforeEnter: requireAuth
  },
  {
    path: '/playground',
    name: 'playground',
    component: PlaygroundView,
    beforeEnter: requireAuth
  },
  {
    path: '/clinics',
    name: 'clinics',
    component: ClinicsView,
    beforeEnter: requireAuth
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
