<template>
    <div class="auth-container">

        <pre class="green-text">
    _    _____   __  __ 
   / \  |_   _| |  \/  | 
  / _ \   | |   | |\/| | 
 / ___ \  | |   | |  | | 
/_/   \_\ |_|   |_|  |_| 
</pre>
        <GoogleSignInButton />
        <!-- You can add more authentication methods here in the future -->
    </div>
</template>
  
<script>
import GoogleSignInButton from '@/components/GoogleSignInButton.vue';

export default {
    name: 'AuthView',
    components: {
        GoogleSignInButton
    }
};
</script>
  
<style>
/* Style your auth view here */
.green-text {
    color: #2afe14;
    font-family: 'Courier New', monospace;
    white-space: pre;
}
</style>
  