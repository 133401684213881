<template>
    <v-data-table :headers="headers" :items="leads" height="1000" class="elevation-1  pr-6 ml-6" :items-per-page="500">
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title class="mr-6">Leads Scraped</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="500px">
                    <template v-slot:activator="{ props }">
                        <v-btn color="primary" dark class="mb-2" v-bind="props">
                            Email config
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-text-field v-model="subject" label="Subject"></v-text-field>
                                </v-row>
                                <v-row>
                                    <v-textarea v-model="emailBody" label="Email" auto-grow></v-textarea>
                                </v-row>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue-darken-1" variant="text" @click="close">
                                Cancel
                            </v-btn>
                            <v-btn color="blue-darken-1" variant="text" @click="save">
                                Save
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>

        <template v-slot:[`item.website`]="{ item }">
            <a :href="item.website" target="_blank" rel="noopener noreferrer">{{ item.website }}</a>
        </template>

        <template v-slot:[`item.actions`]="{ item }">

            <v-icon size="small" class="me-2" @click="sendEmail(item)">
                mdi-email
            </v-icon>

        </template>
        <template v-slot:[`item.actions2`]="{ item }">
            <v-icon size="small" class="me-2" @click="sentEmailCheck(item)">
                mdi-check
            </v-icon>
        </template>
        <template v-slot:[`item.actions3`]="{ item }">
            <v-icon size="small" class="me-2" @click="deleteItem(item)">
                mdi-trash-can-outline
            </v-icon>
        </template>
        <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">
                Reset
            </v-btn>
        </template>
    </v-data-table>
</template>
  
<script>
import { db } from '@/firebase.js'; // Adjust the path as necessary
import posthog from 'posthog-js'

export default {
    data() {
        return {
            subject: '[practice] x mdhub: save time writing clinical notes',
            emailBody: `Hi [name],

I am Dominik, co-founder and CEO of mdhub (https://www.mdhub.ai). We are on a mission to improve the work-life balance for mental health clinician. Our starting point is to automate clinical note-taking.

mdhub co-pilot ambient records and transcribes patient conversations, generating structured clinical notes moments after the session. Over 1,000 clinicians across the country are already saving up to 2 hours daily, allowing them to focus entirely on their patients.

Best of all - it only takes 30 seconds to set up, and you can start a free trial now without needing a credit card. Give it a try and I would love to hear your feedback.

Feel free to reach out with questions or schedule a call with me through my Calendly (https://calendly.com/dm--er0/30min?month=2023-12).

Looking forward to hearing from you.

Dominik
`,
            dialog: false,
            leads: [],
            headers: [
                { title: 'Send email', value: 'actions', sortable: false }, // Non-sortable column
                { title: 'Email sent', value: 'actions2', sortable: false }, // Non-sortable column
                { title: 'Email sent', value: 'emailSent', sortable: true }, // Sortable column
                { title: 'Name', value: 'name', sortable: true }, // Sortable column
                { title: 'Address', value: 'address', sortable: true }, // Sortable column
                { title: 'Phone', value: 'phone', sortable: true }, // Sortable column
                { title: 'Email', value: 'email', sortable: true }, // Non-sortable column
                { title: 'Type', value: 'type', sortable: true }, // Sortable column
                { title: 'Types', value: 'types', sortable: true }, // Sortable column
                { title: 'Website', value: 'website', sortable: false }, // Non-sortable column
                { title: 'Place ID', value: 'place_id', sortable: true }, // Sortable column
                { title: 'Signed Up', value: 'signedUp', sortable: true }, // Sortable column
                { title: 'Delete', value: 'actions3', sortable: false }, // Non-sortable column
            ],
        };
    },
    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },
    mounted() {
        this.fetchLeads();
    },
    methods: {

        emailConfig() {
            console.log()
        },

        async deleteItem(item) {
            try {
                if (!item.place_id) {
                    throw new Error("Item does not have a valid identifier");
                }

                // Delete the document from Firestore
                await db.collection('leads').doc(item.place_id).delete();
                console.log('Item deleted:', item.place_id);

                // Remove the item from the local state
                this.leads = this.leads.filter(lead => lead.place_id !== item.place_id);
            } catch (error) {
                console.error('Error deleting item:', error);
            }
        },

        async sentEmailCheck(item) {
            try {
                // Assuming item.place_id is the document ID
                await db.collection('leads').doc(item.place_id).update({
                    emailSent: true
                });
                console.log('Email sent status updated for', item.place_id);
                posthog.capture("Email sent", { placeId: item.place_id });
                // Optionally, update the local state to reflect the change
                const lead = this.leads.find(lead => lead.place_id === item.place_id);
                if (lead) {
                    lead.emailSent = true;
                }
            } catch (error) {
                console.error('Error updating email sent status:', error);
            }
        },
        sendEmail(item) {
            let personalizedEmailBody = this.emailBody
                .replace('[name]', item.name) // Replace [name] with item.name

            let personalizedEmailSubject = this.subject.replace('[practice]', item.name); // Replace [practice] with item.practice (assuming item has a practice property)

            let emailBody = encodeURIComponent(personalizedEmailBody);
            let subject = encodeURIComponent(personalizedEmailSubject);

            let to = item.email; // Assuming item.email is the email address
            let mailtoLink = `mailto:${to}?subject=${subject}&body=${emailBody}`;

            window.location.href = mailtoLink;
        },
        async fetchLeads() {
            try {
                const querySnapshot = await db.collection('leads').get();
                this.leads = querySnapshot.docs.map(doc => doc.data());
            } catch (error) {
                console.error('Error fetching leads:', error);
            }
        },
        save() {
            this.close();
        },
        close() {
            this.dialog = false;
        },

    },

};
</script>
