<template>
    <button @click="signInWithGoogleMethod" class="google-sign-in-button mt-6">
        Let's go
    </button>
</template>
  
<script>

import { useRouter } from 'vue-router'
import { GoogleAuthProvider, getAuth, signInWithPopup, } from "firebase/auth";

export default {
    name: 'GoogleSignInButton',
    methods: {
        async signInWithGoogleMethod() {
            try {

                const provider = new GoogleAuthProvider();
                const auth = getAuth();
                const result = await signInWithPopup(auth, provider)

                if (!result) {
                    throw new Error('Could not complete signup')
                }
                const user = result.user;

                const idTokenResult = await user.getIdTokenResult()
                if (idTokenResult.claims.admin) {
                    this.router.push({ name: "home" });
                } else {
                    auth.signOut()
                }

            } catch (error) {
                console.error(error);
                // Handle errors, e.g., show a notification
            }
        }
    },
    setup() {
        const router = useRouter();
        return { router }
    }


};
</script>
  
<style>
/* Style your button here */
.google-sign-in-button {
    /* button styles */
}
</style>
  