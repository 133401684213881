<template>
  <v-app>
    <v-app-bar v-if="!isAuthPage" app elevation="1" class="pl-6">
      <img alt="mdScraper" src="./assets/mdhub_white.png" height="50" />
    </v-app-bar>
    <NavDrawer v-if="!isAuthPage"></NavDrawer>

    <v-main class="mt-6 main-background">
      <router-view />
    </v-main>
  </v-app>
</template>

<script setup>
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import NavDrawer from '@/components/NavDrawer.vue';

const route = useRoute();

const isAuthPage = computed(() => route.path === '/auth');
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
