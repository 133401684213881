<template>
    <v-container>

        <!-- File Input with ref Attribute -->
        <input type="file" ref="fileInput" @change="loadJsonFile" accept=".json" style="display: none;">

        <!-- Vuetify Button -->

        <v-data-table :headers="headers" :color="black" :items="items" height="800" item-key="place_id"
            class="elevation-1 test" :items-per-page="50">

            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title class="mr-6">Visualize data</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-btn class="mr-4" color="#071022" @click="triggerFileInput">Select JSON File</v-btn>
                    <v-btn :disabled="items.length === 0" color="#ff7260" @click="syncUsersToFirebase">Sync all
                        users</v-btn>
                    <v-btn :disabled="items.length === 0" color="#ff7260" @click="syncUsersWithEmailsToFirebase">Sync users
                        with
                        emails</v-btn>
                </v-toolbar>
            </template>



            <template v-slot:[`item.website`]="{ item }">
                <a :href="item.website" target="_blank" rel="noopener noreferrer">{{ item.website }}</a>
            </template>
        </v-data-table>

        <v-snackbar v-model="snackbar" :timeout="3000" bottom right color="gray" elevation="24" class="text-center">
            Data successfully uploaded to the DB!
            <template v-slot:action="{ attrs }">
                <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">Close</v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>
  
<script>
// Import the JSON file directly


import { db } from '@/firebase.js'; // Adjust the path as necessary

export default {
    data() {
        return {
            snackbar: false,
            headers: [
                { title: 'Name', value: 'name', },
                { title: 'Address', value: 'address' },
                { title: 'Phone', value: 'phone' },
                { title: 'Email', value: 'email', sortable: true },
                { title: 'Type', value: 'type' },
                { title: 'Types', value: 'types' },
                { title: 'Website', value: 'website', sortable: true },
                { title: 'Place ID', value: 'place_id' },
                { title: 'Signed Up', value: 'signedUp' }
            ],
            items: [], // Assign the imported JSON data to items
        };
    },
    methods: {
        triggerFileInput() {
            // Trigger the click event of the hidden file input
            this.$refs.fileInput.click();
        },

        loadJsonFile(event) {
            const fileReader = new FileReader();
            fileReader.onload = (e) => {
                try {
                    this.items = JSON.parse(e.target.result);
                } catch (error) {
                    console.error('Error parsing JSON:', error);
                }
            };
            fileReader.readAsText(event.target.files[0]);
        },
        async syncUsersToFirebase() {
            try {
                const batch = db.batch();
                let storedCount = 0; // Counter for stored items
                let skippedCount = 0; // Counter for skipped items

                this.items.forEach((item) => {
                    if (item.place_id) {
                        const docRef = db.collection('leads').doc(item.place_id); // Use place_id as the document ID
                        batch.set(docRef, item);
                        storedCount++; // Increment the stored counter
                    } else {
                        console.warn('Item missing place_id, skipped:', item);
                        skippedCount++; // Increment the skipped counter
                    }
                });
                await batch.commit();
                this.snackbar = true;
                console.log(`Data successfully uploaded to Firestore! ${storedCount} items stored.`);
                console.log(`${skippedCount} items were skipped.`);
            } catch (error) {
                console.error('Error uploading data to Firestore:', error);
            }
        },
        async syncUsersWithEmailsToFirebase() {
            try {
                const batch = db.batch();
                let storedCount = 0; // Counter for stored items
                let skippedCount = 0; // Counter for skipped items


                this.items.forEach((item) => {
                    if (item.place_id && item.email && item.email.length > 0) {
                        const docRef = db.collection('leads').doc(item.place_id); // Use place_id as the document ID
                        batch.set(docRef, item);
                        storedCount++; // Increment the stored counter
                    } else {
                        console.warn('Item missing place_id or emails, skipped:', item);
                        skippedCount++; // Increment the skipped counter
                    }
                });
                await batch.commit();
                this.snackbar = true;
                console.log(`Data successfully uploaded to Firestore! ${storedCount} items stored.`);
                console.log(`${skippedCount} items were skipped.`);
            } catch (error) {
                console.error('Error uploading data to Firestore:', error);
            }
        }

    }
};
</script>



<style>
.test .theme--light.v-table {
    background-color: #00f;
}
</style>
  