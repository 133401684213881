<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="6">
                <ScraperMain :top-left="selectedTopLeft" :bottom-right="selectedBottomRight" :city-name="selectedCityName">
                </ScraperMain>
            </v-col>
            <v-col cols="12" md="6">
                <CityTableData @select-element="handleSelectElement"></CityTableData>
            </v-col>
        </v-row>
        <TableData></TableData>
    </v-container>
</template>


<script>
import ScraperMain from '@/components/ScraperMain'
import TableData from '@/components/TableData'
import CityTableData from '@/components/CityTableData.vue'

export default {
    components: {
        ScraperMain,
        TableData,
        CityTableData
    },
    data() {
        return {
            selectedTopLeft: '',
            selectedBottomRight: '',
            selectedCityName: ''
        };
    },
    methods: {
        handleSelectElement(cityName, topLeft, bottomRight) {
            this.selectedCityName = cityName;
            this.selectedTopLeft = topLeft;
            this.selectedBottomRight = bottomRight;
        }

    },
    setup() {
        console.log("Scraper view")
    }
}
</script>

<style scoped></style>