<template>
    <LeadsView></LeadsView>
</template>

<script>
import LeadsView from '@/components/LeadsTable.vue'


export default {
    components: {
        LeadsView
    },
    setup() {
        console.log("Scraper view")
    }
}
</script>

<style scoped></style>